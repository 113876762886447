<template>
  <MainContent />
</template>

<script>
import MainContent from "@/components/MainContent.vue";

export default {
  name: 'App',
  components: {
    MainContent,
  }
}
</script>

<style>
@font-face {
  font-family: "Pixeloid";
  font-weight: 300;
  src: url('./assets/fonts/pixeloid/PixeloidMono.ttf');
}
@font-face {
  font-family: "Pixeloid";
  font-weight: 400;
  src: url('./assets/fonts/pixeloid/PixeloidSans.ttf');
}
@font-face {
  font-family: "Pixeloid";
  font-weight: 700;
  src: url('./assets/fonts/pixeloid/PixeloidSansBold.ttf');
}
html {
  height: 100vh;
  width: 100vw;
}
html, body, #app {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: Pixeloid, sans-serif;
}
</style>
