<template>
  <div class="container">
    <h1 class="title">JPEG Trading</h1>
    <p class="services">
      venture investing
      <br />
      liquidity provisioning
      <br />
      market making
    </p>
    <p class="pitch">
      We partner with foundations to transparently and productively support their ecosystem.
      <br />
      <br />
    </p>
    <div class="space" />
  </div>
</template>

<script>
export default {
  name: 'MainContent'
}
</script>

<style>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.title {
  text-align: center;
  font-size: 4.5rem;
  font-weight: 400;
  margin: 0;
}
.services {
  text-align: center;
  font-weight: 300;
  margin-top: 0.75rem;
}
.pitch {
  text-align: center;
  width: max-content;
  font-weight: 300;
  font-size: 0.875rem;
  margin-top: 3rem;
}
.typeform-link {
  text-decoration: none;
  color: black;
}
.typeform-link:hover {
  text-decoration: underline;
}
.clients-heading {
  margin-top: 4rem;
  font-size: 1.125rem;
  font-weight: 300;
  text-decoration: underline;
}
.clients-container {
  margin-bottom: 4rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  gap: 1.75rem;
}
.client, .client-logo {
  height: 100%;
}
.space {
  height: 6rem;
}
@media only screen and (max-width: 900px) {
  .pitch {
    max-width: 80vw;
  }
}
@media only screen and (max-width: 600px) {
  .container {
    height: max-content;
    padding: 1rem;
  }
  .clients-container {
    flex-direction: column !important;
  }
  .space {
    display: block;
  }
}
</style>
